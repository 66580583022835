import ListModelBase from "@/apps/core/models/listModelBase.js";

const modelName = "angkatanList";
const modelPath = "/programstudi/angkatan/";

class AngkatanList extends ListModelBase {
  constructor() {
    super(modelName, modelPath);
  }
}

export default AngkatanList;